import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/iconfont/iconfont.css'
import './assets/iconfont/iconfont.js'
import './assets/iconfonts/iconfont.css'
import './assets/iconfonts/iconfont.js'
import 'swiper/css/swiper.css'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

//Vue.use(Viewer) 默认配置写法
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999
  }
})
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  mounted () {
    setTimeout(() => {
      document.dispatchEvent(new Event('render-event'))
    }, 5000)
  }
}).$mount('#app')
