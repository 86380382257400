<template>
    <div class="mobileHome" style="margin-top:10px;">
        <div class="banner-box">
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <router-link to="/technology?index=1&wapShow=true">
                            <img src="../../../assets/image/banner4.png" alt="">
                            <span class="swiper-slide-banner4-text1">熔融结晶</span>
                            <span class="swiper-slide-banner4-text2">热敏及精馏难分离物系的优选分离工艺<br>高纯化学品生产的可靠解决方法</span>
                        </router-link>
                    </div>
                    <div class="swiper-slide">
                        <img src="../../../assets/image/banner1.png" alt="">
                        <span class="swiper-slide-banner1-text">绿色化工践行者</span>
                    </div>
                    <div class="swiper-slide">
                        <img src="../../../assets/image/banner2.png" alt="">
                        <span class="swiper-slide-banner1-text">模块化系统专家</span>
                    </div>
                    <div class="swiper-slide">
                        <img src="../../../assets/image/banner3.png" alt="">
                        <span class="swiper-slide-banner1-text">智能化工建设者</span>
                    </div>
                </div>
                <div class="swiper-pagination"></div>
            </div>
        </div>

        <div class="title">
            技术&解决方案
            <div class="title-en">TECHNOLOGY & SOLUTIO</div>
        </div>

        <div class="t-item t-item1">
            <div class="t-item-title">单元分离技术及设备</div>
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <router-link to="/technology?index=1&wapShow=true" class="item-detail">
                            <img src="../../../assets/image/item1.png" alt="">
                            <div>熔融结晶</div>
                        </router-link>
                    </div>
                    <div class="swiper-slide">
                        <router-link to="/technology?index=3&wapShow=true" class="item-detail">
                            <img src="../../../assets/image/item2.png" alt="">
                            <div>升华结晶</div>
                        </router-link>
                    </div>
                    <div class="swiper-slide">
                        <router-link to="/technology?index=4&wapShow=true" class="item-detail">
                            <img src="../../../assets/image/item3.png" alt="">
                            <div>连续萃取</div>
                        </router-link>
                    </div>
                </div>

            </div>
            <!-- <div class="swiper-button-prev prev iconfont icon-xiangzuojiantou"></div>
            <div class="swiper-button-next next iconfont icon-xiangyoujiantou"></div> -->
        </div>
        <div class="t-item t-item2" style="margin-top: 14px">
            <div class="t-item-title">产品工艺技术</div>
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <router-link to="/technologys?index=1&wapShow=true" class="item-details">
                            <img src="../../../assets/image/item4.png" alt="">
                            <div style="height:42px;line-height: 20px;"><span>锂电添加剂<br>系列产品</span></div>
                        </router-link>
                    </div>
                    <div class="swiper-slide">
                        <router-link to="/technologys?index=2&wapShow=true" class="item-details">
                            <img src="../../../assets/image/item5.png" alt="">
                            <div style="height:42px;line-height: 20px;">氰化及衍生物<br>系列产品</div>
                        </router-link>
                    </div>
                    <div class="swiper-slide">
                        <router-link to="/technologys?index=3&wapShow=true" class="item-details">
                            <img src="../../../assets/image/item6.png" alt="">
                            <div style="height:42px;line-height: 20px;">三废资源化<br>利用技术</div>
                        </router-link>
                    </div>
                </div>
            </div>
           <!-- <div class="swiper-button-prev prev iconfont icon-xiangzuojiantou"></div>
            <div class="swiper-button-next next iconfont icon-xiangyoujiantou"></div> -->
        </div>

        <div class="title">
            服务流程
            <div class="title-en">SERVICE</div>
        </div>
        <div class="service-box">
            <div class="service-box-item" @click="clickImg(1)">
                <img src="../../../assets/image/service1.png" alt="">
                <router-link to="/server?index=1&wapShow=true" class="service-line">
                    <div>研究与测试</div>
                    <div class="service-icon iconfont icon-youjiantou"></div>
                </router-link>
            </div>
            <div class="service-box-item"  @click="clickImg(2)">
                <img src="../../../assets/image/service2.png" alt="">
                <router-link to="/server?index=2&wapShow=true" class="service-line">
                    <div>工程设计</div>
                    <div class="service-icon iconfont icon-youjiantou"></div>
                </router-link>
            </div>
            <div class="service-box-item"  @click="clickImg(3)">
                <img src="../../../assets/image/service3.png" alt="">
                <router-link to="/server?index=3&wapShow=true" class="service-line">
                    <div>设备制造和系统集成</div>
                    <div class="service-icon iconfont icon-youjiantou"></div>
                </router-link>
            </div>
            <div class="service-box-item"  @click="clickImg(4)">
                <img src="../../../assets/image/service4.png" alt="">
                <router-link to="/server?index=4&wapShow=true" class="service-line">
                    <div>交付及售后服务</div>
                    <div class="service-icon iconfont icon-youjiantou"></div>
                </router-link>
            </div>
            <div style="clear: both"></div>
        </div>
        <div class="title">
            资讯与动态
            <div class="title-en">NEWS</div>
        </div>
        <div class="news-box">
            <div class="news-box-item" v-for="item in lists2" @click="to_detail(item.id)">
                <img :src="require('@/assets/image/message/'+item.imgurl)"/>
                <div class="news-box-title">{{item.name}}</div>
                <div class="news-box-cont">{{item.contents}}</div>
                <div class="news-box-time">{{item.time}}</div>
            </div>
        </div>
        <div class="news-box">
            <div class="news-box-item" v-for="item in lists" @click="to_detail(item.id)">
                <img :src="require('@/assets/image/message/'+item.imgurl)"/>
                <div class="news-box-title">{{item.name}}</div>
                <div class="news-box-cont">{{item.contents}}</div>
                <div class="news-box-time">{{item.time}}</div>
            </div>
        </div>

        <mobileFooter></mobileFooter>
    </div>
</template>

<script>
    import Swiper, {Navigation, Pagination, Autoplay} from 'swiper';
    import mobileFooter from '../../../components/mobile_footer';

    export default {
        name: 'mobileHome',
        data() {
            return {
                lists: [],
                lists2:[]
            }
        },
        components:{mobileFooter},
        mounted() {
            document.getElementsByTagName("title")[0].innerText = '重庆敏恒科技 | 专注熔融结晶|VC熔融结晶|FEC熔融结晶';
            this.lists = JSON.parse(JSON.stringify(require('@/assets/js/data.js').default)).splice(1, 3);
            this.lists2 = JSON.parse(JSON.stringify(require('@/assets/js/data.js').default)).splice(5, 7);
            let swiper1 = new Swiper('.banner-box .swiper-container', {
                autoplay: {
                },
                loop: true,
                pagination: {
                    el: '.banner-box .swiper-pagination',
                    clickable: true,
                },

            })
            let swiper2 = new Swiper('.t-item1 .swiper-container', {
                autoplay: {
                    pauseOnMouseEnter: true,
                },
                slidesPerView: 3,
                effect: "coverflow",
                coverflowEffect: {
                    rotate: 0,
                    stretch: 0, //每个slide之间的拉伸值（距离），越大slide靠得越紧。 默认0。
                    depth: 0, //slide的位置深度。值越大z轴距离越远，看起来越小。 默认100。
                    modifier: 1,//depth和rotate和stretch的倍率，相当于            depth*modifier、rotate*modifier、stretch*modifier，值越大这三个参数的效果越明显。默认1。
                    slideShadows: false, //开启slide阴影。默认 true。
                },
                loop: true,
                navigation: {
                    nextEl: '.t-item1 .swiper-button-next',
                    prevEl: '.t-item1 .swiper-button-prev',
                },

            })
            let swiper3 = new Swiper('.t-item2 .swiper-container', {
                autoplay: {
                    pauseOnMouseEnter: true,
                },
                effect: "coverflow",
                coverflowEffect: {
                    rotate: 0,
                    stretch: 0, //每个slide之间的拉伸值（距离），越大slide靠得越紧。 默认0。
                    depth: 0, //slide的位置深度。值越大z轴距离越远，看起来越小。 默认100。
                    modifier: 1,//depth和rotate和stretch的倍率，相当于            depth*modifier、rotate*modifier、stretch*modifier，值越大这三个参数的效果越明显。默认1。
                    slideShadows: false, //开启slide阴影。默认 true。
                },
                slidesPerView: 3,
                loop: true,
                navigation: {
                    nextEl: '.t-item2 .swiper-button-next',
                    prevEl: '.t-item2 .swiper-button-prev',
                },

            })
        },
        methods: {
            to_detail(id) {
                this.$router.push({
                    path: "/messageDetail",
                    query: {
                        id: id,
                        wapShow:true
                    }
                })
            },
            clickImg(num){
                switch (num) {
                    case 1:
                        this.$router.push({
                            path: "/server?index=1",
                            query:{wapShow:true}
                        });
                        break;
                    case 2:
                        this.$router.push({
                            path: "/server?index=2",
                            query:{wapShow:true}
                        });
                        break;
                    case 3:
                        this.$router.push({
                            path: "/server?index=3",
                            query:{wapShow:true}
                        });
                        break;
                    case 4:
                        this.$router.push({
                            path: "/server?index=4",
                            query:{wapShow:true}
                        });
                        break;
                }
            },
        }

    }
</script>
<style scoped>

    /*@import "../../../assets/css/mobileHome.css";*/

    .mobileHome{
        width: 375px;
        background: #FFFFFF;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100%;
        margin:0 auto;
    }
    .title {
        margin-top: 33px;
        margin-bottom: 14px;
        text-align: center;
        font-size: 15px;
        font-weight: 500;
        color: #363A44;
        letter-spacing: 1px;
        position: relative;
    }

    .title-en {
        position: absolute;
        text-align: center;
        font-size: 20px;
        font-family: Helvetica;
        color: rgba(54, 58, 68, 0.08);
        top: -11px;
        width: 100%;
    }
    .banner-box{
        width:355px;
        height: 129px;
        overflow: hidden;
        margin:0 auto 0 auto;
    }
    .banner-box .swiper-container{
        height: 129px;
        border-radius: 0px 0px 10px 10px;
    }
    .banner-box .swiper-container .swiper-wrapper{
        display: flex;
    }
    .banner-box img{
        height: 129px;
        object-fit: cover;
    }

    .banner-box .swiper-pagination{
        bottom: 1px !important;
    }

    .banner-box .swiper-pagination .swiper-pagination-bullet{
        width: 40px;
        height: 1px;
        background: #fff;
        opacity: 1;
    }

    .banner-box .swiper-pagination .swiper-pagination-bullet-active{
        background: #0079FF;
    }
    .t-item .swiper-container{
        top: 44px;
        width: 335px;
        margin: 0 auto;
    }
    .t-item .swiper-container .swiper-slide{
        display: flex;
        justify-content: center;
    }
    .prev,.next{
        color: #3A3A3A !important;
        font-size: 17px !important;
        font-weight: bold;
    }
    .prev:hover,.next:hover{
        color: #0079FF !important;
    }
    .prev::after,.next::after{
        display: none;
    }
    .item-detail{
        margin: 0 16px;
        text-decoration: none;
    }
    .item-detail img{
        width: 105px;
        height: 80px;
        object-fit: cover;
        border-radius: 10px 10px 0px 0px;
    }
    .item-details img{
        width: 105px;
        height: 80px;
        object-fit: cover;
        border-radius: 10px 10px 0px 0px;
    }
    .item-details div{
        text-decoration: none;
        width: 97px;
        height: 42px;
        line-height: 20px;
        font-size: 12px;
        padding-left: 8px;
        background: rgba(52, 52, 52, 0.9);
        text-align: left;
        border-radius: 0px 0px 10px 10px;
        margin-top: -13px;
        color: #fff;
    }
    .item-detail div{
        width: 97px;
        height: 25px;
        line-height: 25px;
        font-size: 12px;
        padding-left: 8px;
        background: rgba(52, 52, 52, 0.9);
        text-align: left;
        border-radius: 0px 0px 10px 10px;
        margin-top: -13px;
        color: #fff;
    }
    .t-item{
        width:355px;
        height: 185px;
        background-image: url("../../../assets/image/mobile/item-bg.png");
        background-size: contain;
        position: relative;
        margin:0 auto;
    }
    .t-item-title{
        position: absolute;
        top: 15px;
        text-align: center;
        font-size: 13px;
        font-weight: 500;
        color: #3A3A3A;
        width: 100%;
    }
    .service-box{
        margin:0 auto;
        width:355px;
    }
    .service-box-item a{
        text-decoration: none;
    }
    .service-box-item{
        float: left;
        width: 172px;
        cursor: pointer;
        margin-bottom: 14px;
    }
    .service-box-item:hover .service-icon{
        color: #0079FF;
    }
    .service-box-item:hover .service-line{
        border-color: #0079FF;
    }
    .service-icon{
        color: #363A44;
        font-size: 16px !important;
    }
    .service-box-item img{
        width: 172px;
        height: 82px;
        object-fit: cover;
    }
    .service-box-item:nth-child(2n){
        margin-left: 11px;
    }

    .service-line{
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        color: #363A44;
        border-bottom: 1px solid rgba(54, 58, 68, 0.14);
    }
    .news-box{
        width:355px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    .news-box-item{
        width: 111px;
        height: 180px;
        background: #FFFFFF;
        box-shadow: 0px 0px 20px 4px rgba(0, 0, 0, 0.04);
        border-radius: 10px;
        overflow: hidden;
        text-align: start;
        cursor: pointer;
    }
    .news-box-item img{
        width: 111px;
        height: 72px;
        object-fit: cover;
    }
    .news-box-title{
        margin: 0 8px 3px 8px;
        font-size: 12px;
        color: #000;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .news-box-title:hover{
        color: #0079FF;
    }
    .news-box-cont{
        margin: 0 8px;
        font-size: 10px;
        color: #828282;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .news-box-time{
        margin: 4px 8px;
        padding-bottom:2px;
        font-size: 10px;
        height:14px;
        color: rgba(0, 0, 0, 0.49);;
    }

    /*banner图文字*/
    .swiper-slide-banner4-text1{
        position: absolute;
        top:29px;
        left:47px;
        width: 77px;
        height: 27px;
        font-size: 19px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 27px;
    }
    .swiper-slide-banner4-text2{
        position: absolute;
        left:47px;
        top:58px;
        text-align: left;
        width: 219px;
        height: 36px;
        font-size: 12px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 18px;
    }
    .swiper-slide-banner1-text{
        position: absolute;
        top:53px;
        left:15px;
        width: 324px;
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 22px;
    }


</style>

