<template>
  <div id="app">
    <div class="outer">
		 <!-- 手机端导航 -->
		 <div class="outerNav" v-if="showAll == true">
			<div class="textMain">
			  <div class="textContent">
				<div class="navText1 oneNav" @click="routerChange('/')" style="border-bottom: 0.02rem solid rgba(0,0,0,0.06);">
					<span>首页</span>
					<img src="./assets/image/mobile/arrow-right.png" alt="">
				</div>
				<!--技术解决方案-->
				<div style="border-bottom: 0.02rem solid rgba(0,0,0,0.06);">
					<div class="navText2 oneNav" @click="showTwo(2)" >
						<span>技术&解决方案</span>
						<img src="./assets/image/mobile/add.png" alt="" v-if="showNav2==false">
						<img src="./assets/image/mobile/close.png" alt="" v-else>
					</div>
					<div v-if="showNav2==true" style="color:#696970">
						<div class="twoNav" @click="showTwo(6)">
							<span>单元分离技术及设备</span>
							<img src="./assets/image/mobile/add.png" alt="" v-if="showThreeNav==false">
							<img src="./assets/image/mobile/close.png" alt="" v-else>
						</div>
						<div v-if="showThreeNav==true">
							<div class="threeNav" @click="showTwo(7)">
								<span>熔融结晶</span>
								<img src="./assets/image/mobile/add.png" alt="" v-if="showFourNav==false">
								<img src="./assets/image/mobile/close.png" alt="" v-else>
							</div>
							<div v-if="showFourNav==true">
								<div class="fourNav" @click="routerChange('/technology?index=1')">
									<span>熔融结晶</span>
									<img src="./assets/image/mobile/arrow-right.png" alt="">
								</div>
								<div class="fourNav" @click="routerChange('/technology?index=2')">
									<span>设备与系统</span>
									<img src="./assets/image/mobile/arrow-right.png" alt="">
								</div>
							</div>
							<div class="threeNav" @click="routerChange('/technology?index=3')">
								<span>升华结晶</span>
								<img src="./assets/image/mobile/arrow-right.png" alt="">
							</div>
							<div class="threeNav" @click="routerChange('/technology?index=4')">
								<span>连续萃取</span>
								<img src="./assets/image/mobile/arrow-right.png" alt="">
							</div>
						</div>
						<div class="twoNav" @click="showTwo(8)">
							<span>产品工艺技术</span>
							<img src="./assets/image/mobile/add.png" alt="" v-if="showFourNav2==false">
							<img src="./assets/image/mobile/close.png" alt="" v-else>
						</div>
						<div v-if="showFourNav2==true">
							<div class="threeNav" @click="routerChange('/technologys?index=1')">
								<span>锂电添加剂系列产品</span>
								<img src="./assets/image/mobile/arrow-right.png" alt="">
							</div>
							<div class="threeNav" @click="routerChange('/technologys?index=2')">
								<span>氰化及衍生物系列产品</span>
								<img src="./assets/image/mobile/arrow-right.png" alt="">
							</div>
							<div class="threeNav" @click="routerChange('/technologys?index=3')">
								<span>三废资源化利用技术</span>
								<img src="./assets/image/mobile/arrow-right.png" alt="">
							</div>
						</div>
					</div>
				</div>
				<!--技术解决方案end-->

				<!--服务流程-->
				<div style="border-bottom: 0.02rem solid rgba(0,0,0,0.06);">
				  <div class="navText3 oneNav"  @click="showTwo(3)" >
					  <span>服务流程</span>
					  <img src="./assets/image/mobile/add.png" alt="" v-if="showNav3==false">
					  <img src="./assets/image/mobile/close.png" alt="" v-else>
				  </div>
				  <div v-if="showNav3==true" style="color:#696970">
					  <div class="twoNav" @click="routerChange('/server?index=1')">
						  <span>研究与测试</span>
						  <img src="./assets/image/mobile/arrow-right.png" alt="">
					  </div>
					  <div class="twoNav" @click="routerChange('/server?index=2')">
						  <span>工程设计</span>
						  <img src="./assets/image/mobile/arrow-right.png" alt="">
					  </div>
					  <div class="twoNav" @click="routerChange('/server?index=3')">
						  <span>设备制造和系统集成</span>
						  <img src="./assets/image/mobile/arrow-right.png" alt="">
					  </div>
					  <div class="twoNav" @click="routerChange('/server?index=4')">
						  <span>售后服务</span>
						  <img src="./assets/image/mobile/arrow-right.png" alt="">
					  </div>
				  </div>
			  </div>
				<!--服务流程end-->

				<div class="navText4 oneNav" @click="routerChange('/message')"  style="border-bottom: 0.02rem solid rgba(0,0,0,0.06);">
				  <span>资讯与动态</span>
				  <img src="./assets/image/mobile/arrow-right.png" alt="">
				</div>

				<!--关于我们-->
				<div  style="border-bottom: 0.02rem solid rgba(0,0,0,0.06);">
					<div class="navText5 oneNav"  @click="showTwo(5)">
						<span>关于我们</span>
						<img src="./assets/image/mobile/add.png" alt="" v-if="showNav5==false">
						<img src="./assets/image/mobile/close.png" alt="" v-else>
					</div>
					<div v-if="showNav5==true" style="color:#696970">
						<div class="twoNav" @click="routerChange('/about?index=1')">
							<span>公司介绍</span>
							<img src="./assets/image/mobile/arrow-right.png" alt="">
						</div>
						<div class="twoNav" @click="routerChange('/about?index=2')">
							<span>职业机会</span>
							<img src="./assets/image/mobile/arrow-right.png" alt="">
						</div>
						<div class="twoNav" @click="routerChange('/about?index=3')">
							<span>联系我们</span>
							<img src="./assets/image/mobile/arrow-right.png" alt="">
						</div>
					</div>
				<!--关于我们end-->
				</div>
			  </div>
			</div>
		</div>

		 <div class="header" v-if="wapShow==false">
			<img src="../src/assets/image/logo.png" alt="" style="cursor: pointer" @click="to_home()"/>
			 <div style="width: 140px;">
				 <img src="../src/assets/image/phone.png" alt="" style="width: 30px;height:29px;margin-top: 4px;float: left;display: inline-block">
				 <div style="float: right;font-size: 14px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #000000;">
					 185 8087 5987<br>023-67665596
				 </div>
			 </div>
		  </div>

		 <div class="header-mobile" v-if="wapShow==true">
			  <div class="header-mobile-content">
				  <img src="../src/assets/image/logo.png" alt="" style="cursor: pointer" @click="to_mobileHome()"/>
				  <div>
					<img src="../src/assets/image/mobile/menu2.png" alt="" @click="clickNav" v-if="showAll == true">
					<img src="../src/assets/image/mobile/menu.png" alt="" @click="clickNav" v-else>
				  </div>
			  </div>
		  </div>

		 <nav class="nav-box" v-if="wapShow==false">
			<router-link class="nav-bar active1" to="/">首页</router-link>
			<a class="nav-bar">
			  <span class="active1">技术&解决方案</span>
			  <div class="sec-bar-warp">
				<router-link to="/technology"  class="sec-bar">
				  <span style="display: block;width: 100%;height: 100%"  @mousemove="technologyJudge=true" @mouseout="technologyJudge=false">单元分离技术及设备</span>
					<div v-show="technologyJudge" @mousemove="technologyJudge=true" @mouseout="technologyJudge=false" style="margin-left: 194px;margin-top: -50px;">
						<router-link to="/technology?index=1"  class="sec-bar">
							<span style="display: block;width: 100%;height: 100%"  @mousemove="technologyJudge1=true" @mouseout="technologyJudge1=false">熔融结晶</span>
<!--							不删除，只隐藏-->
							<div v-show="technologyJudge1" @mousemove="technologyJudge1=true" @mouseout="technologyJudge1=false" style="margin-left: 194px;margin-top: -50px;">
								<router-link to="/technology?index=2"  class="sec-bar" style="border-radius: 0">设备与系统</router-link>
							</div>
						</router-link>
						<router-link  to="/technology?index=3" class="sec-bar">
							升华结晶
						</router-link>
						<router-link to="/technology?index=4" class="sec-bar" style="border-radius: 0">连续萃取</router-link>
					</div>

				</router-link>
				 <router-link to="/technologys"  class="sec-bar">
				   <span style="display: block;width: 100%;height: 100%"  @mousemove="technologyJudge2=true" @mouseout="technologyJudge2=false">产品工艺技术</span>
				   <div v-show="technologyJudge2" @mousemove="technologyJudge2=true" @mouseout="technologyJudge2=false" style="margin-left: 194px;margin-top: -50px;">
					 <router-link to="/technologys?index=1"  class="sec-bar">锂电添加剂系列产品</router-link>
					 <router-link to="/technologys?index=2"  class="sec-bar">氰化及衍生物系列产品</router-link>
					 <router-link to="/technologys?index=3"  class="sec-bar" style="border-radius: 0">三废资源化利用技术</router-link>
				   </div>
				 </router-link>
			  </div>
			</a>
			<router-link class="nav-bar" to="/server">
			  <span class="active1">服务流程</span>
			  <div class="sec-bar-warp">
				<router-link to="/server?index=1" class="sec-bar">研究与测试</router-link>
				<router-link to="/server?index=2" class="sec-bar">工程设计</router-link>
				<router-link  to="/server?index=3" class="sec-bar">设备制造和系统集成</router-link>
				<router-link to="/server?index=4" class="sec-bar"> 售后服务</router-link>
			  </div>
			</router-link>
			<router-link class="nav-bar" to="/message">
			  <span class="active1">资讯与动态</span>
			  <div class="sec-bar-warp">
				  <router-link class="sec-bar" to="/message">资讯与动态</router-link>
			  </div>
			</router-link>
			<router-link class="nav-bar" to="/about">
			  <span class="active1">关于我们</span>
			  <div class="sec-bar-warp">
				<router-link to="/about?index=1" class="sec-bar">公司介绍</router-link>
				  <router-link to="/about?index=2" class="sec-bar">职业机会</router-link>
			  <router-link to="/about?index=3" class="sec-bar">联系我们</router-link>
			  </div>
			</router-link>
		  </nav>

		 <router-view/>
		 <div class="footer" v-if="wapShow==false">
			<div class="footer-cont">
			  <div class="footer-cont-left">
				<img src="../src/assets/image/logo-s.png" alt="">
<!--				<a >电话：023-67665596</a>-->
<!--				<a>技术咨询：185 2345 4096 李军</a>-->
				<a>商务交流：185 8087 5987 张先生</a>
				<a style="margin-left: 61px">023-67665596</a>
				<a href = "mailto:service@mhkjcq.com">邮箱：service@mhkjcq.com</a>
				<div>地址：重庆市巴南区木洞镇天益路9号重庆国际生物城E3栋2单元</div>
			  </div>
			  <div class="footer-cont-right">
				<div>
				  <div class="footer-cont-right-header">技术&解决方案</div>
				  <router-link to="/technology" class="footer-cont-right-list">单元分离技术及设备</router-link>
				  <router-link to="/technologys" class="footer-cont-right-list">产品工艺技术</router-link>
				</div>
				<div>
				  <div class="footer-cont-right-header">服务流程</div>
				  <router-link to="/server?index=1" class="footer-cont-right-list">研究与测试</router-link>
				  <router-link to="/server?index=2" class="footer-cont-right-list">工程设计</router-link>
				  <router-link to="/server?index=3" class="footer-cont-right-list">设备制造和系统集成</router-link>
				  <router-link to="/server?index=4" class="footer-cont-right-list">售后服务</router-link>
				</div>
				<div>
				  <div class="footer-cont-right-header">资讯与动态</div>
				  <router-link to="/messageDetail?id=6" class="footer-cont-right-list">熔融结晶</router-link>
				  <router-link to="/message" class="footer-cont-right-list">资讯与动态</router-link>
				</div>
				<div>
				  <div class="footer-cont-right-header">关于我们</div>
				  <router-link to="/about?index=1" class="footer-cont-right-list">公司介绍</router-link>
				  <router-link to="/about?index=2" class="footer-cont-right-list">职业机会</router-link>
				  <router-link to="/about?index=3" class="footer-cont-right-list">联系我们</router-link>
				</div>
			  </div>
			</div>
			<div class="copy-right">
			  <div>Copyright © 2020-2022 重庆敏恒科技有限公司</div>
        <div>
          <a href="https://beian.miit.gov.cn" target="_blank" style="color: #ffffff; text-decoration: none;">备案序号：渝ICP备2021011164号-1</a>

          <a href="https://beian.mps.gov.cn/#/query/webSearch?code=50011302222017" rel="noreferrer" target="_blank" style="color: #ffffff; text-decoration: none;margin-left: 10px">
            <img  style="width: 14px;margin-right: 4px" src="./assets/image/ab.png" alt="">
            渝公网安备 50011302222017</a>
        </div>

			</div>
		  </div>
    </div>
  </div>
</template>
<script>
	import eventBus from "./assets/js/eventBus";

	export default {
	name: 'app',
    data(){
      return{
        technologyJudge:false,
        technologyJudge1:false,
        technologyJudge2:false,
		showNav2:false,
		showNav3:false,
		showNav5:false,
		showAll:false,
		showThreeNav:false,
		showFourNav:false,
		showFourNav2:false,
	    wapShow:false
      }
    },
    mounted() {
    //PC端手机端切换
		let _that = this;
		_that.sizes();

		_that.$root.title = '';

		window.addEventListener('resize',()=>{
			_that.sizes()
		})
    },
    watch:{},
    methods:{
      to_home(){
	    let pathName =  window.location.pathname;
	    if(pathName == '/')return;
        this.$router.push({
          path:'/',
        })
      },
	  to_mobileHome(){
		  let pathName =  window.location.pathname;
		  if(pathName == '/')return;
		  this.$router.push({
		    path:'/',
		    query:{wapShow:true}
		  });
	  },
	  //点击打开菜单
	  clickNav(){
		  this.showAll = !this.showAll;
	  },
	  //点击打开二级菜单
	  showTwo(num){
		  switch(num){
		   case 2:
		     this.showNav2 = !this.showNav2;
		     break;
		   case 3:
		     this.showNav3 = !this.showNav3;
		     break;
		   case 5:
		     this.showNav5 = !this.showNav5;
		     break;
		   case 6:
		     this.showThreeNav = !this.showThreeNav;
		     break;
		   case 7:
		     this.showFourNav = !this.showFourNav;
			 break;
		   case 8:
		     this.showFourNav2 = !this.showFourNav2;
			 break;
		  }
	  },
	  //点击导航跳转
	  routerChange(address){
	      this.showAll = false;
		  let pathName =  window.location.pathname;
		  if(address == pathName)return;
		  switch(address){
			case '/':
			  this.$router.push({
			    path:'/',
			    query:{wapShow:true}
			  });
			  break;
		    case '/server?index=1':
			  this.$router.push({
			    path:'/server?index=1',
				  query:{wapShow:true}
			  });
				break;
		    case '/server?index=2':
		      this.$router.push({
		        path:'/server?index=2',
				  query:{wapShow:true}
			  });
		      break;
			case '/server?index=3':
			  this.$router.push({
			    path:'/server?index=3',
				  query:{wapShow:true}
			  });
				break;
			case '/server?index=4':
			  this.$router.push({
			    path:'/server?index=4',
				  query:{wapShow:true}
			  });
				break;
			case '/message':
			  this.$router.push({
			    path:'/message',
				  query:{wapShow:true}
			  });
				break;
			case '/about?index=1':
			  this.$router.push({
			    path:'/about?index=1',
				  query:{wapShow:true}
			  });
				break;
			case '/about?index=2':
			  this.$router.push({
			    path:'/about?index=2',
				  query:{wapShow:true}
			  });
				break;
			case '/about?index=3':
			  this.$router.push({
			    path:'/about?index=3',
				  query:{wapShow:true}
			  });
				break;
			case '/technology?index=1':
			  this.$router.push({
			    path:'/technology?index=1',
				  query:{wapShow:true}
			  });
			  break;
			case '/technology?index=2':
			  this.$router.push({
			    path:'/technology?index=2',
				  query:{wapShow:true}
			  });
			  break;
			case '/technology?index=3':
			  this.$router.push({
			    path:'/technology?index=3',
				  query:{wapShow:true}
			  });
			  break;
			case '/technology?index=4':
			  this.$router.push({
			    path:'/technology?index=4',
				  query:{wapShow:true}
			  });
			  break;
			case '/technologys?index=1':
			  this.$router.push({
			    path:'/technologys?index=1',
				  query:{wapShow:true}
			  });
			  break;
			case '/technologys?index=2':
			  this.$router.push({
			    path:'/technologys?index=2',
				  query:{wapShow:true}
			  });
			  break;
			case '/technologys?index=3':
			  this.$router.push({
			    path:'/technologys?index=3',
				  query:{wapShow:true}
			  });
			  break;
		  }
	  },

	//PC端手机端切换
		sizes(){
			let _that = this;
			!function (window) {
				/* 设计图文档宽度 */
				var docWidth = 1500;
				var doc = window.document,
						docEl = doc.documentElement,
						resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize';
				var recalc = (function refreshRem() {
					/* 窗口当前宽度 */
					var clientWidth = window.innerWidth;
					if (clientWidth > 1200) {
						var oldSize = (clientWidth / docWidth * 100) + 'px';
						docEl.style.fontSize = 12 + 'px';
						_that.wapShow=false
						eventBus.$emit("wapShow",false)
					} else {
						docWidth = 750;
						_that.wapShow=true
						var oldSize1 = (clientWidth / docWidth * 100) + 'px';
						docEl.style.fontSize = oldSize1;
						eventBus.$emit("wapShow",true)
					}

					//
					/* 获取设置后的字体大小情况 - 因微信设置APP字体大小后会影响该设置 */
					var nowSize = window.getComputedStyle(document.getElementsByTagName("html")[0]).fontSize;
					var oldSizeValue = parseFloat(oldSize, 10);
					var nowSizeValue = parseFloat(nowSize, 10);
					/* 当差值大于1时重新按差比计算出正确的px值 */
					if ((nowSizeValue - oldSizeValue) > 1 || (nowSizeValue - oldSizeValue) < -1) {
						var diff = (oldSizeValue / nowSizeValue);
						// docEl.style.fontSize = (clientWidth/docWidth* 100*diff) + 'px';
					}
					return refreshRem;
				})();
				/* 添加倍屏标识，安卓为1 */
				docEl.setAttribute('data-dpr', window.navigator.appVersion.match(/iphone/gi) ? window.devicePixelRatio : 1);
				if (/iP(hone|od|ad)/.test(window.navigator.userAgent)) {
					/* 添加IOS标识 */
					doc.documentElement.classList.add('ios');
					/* IOS8以上给html添加hairline样式，以便特殊处理 */
					if (parseInt(window.navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/)[1], 10) >= 8)
						doc.documentElement.classList.add('hairline');
				}
				if (!doc.addEventListener) return;
				window.addEventListener(resizeEvt, recalc, false);
				doc.addEventListener('DOMContentLoaded', recalc, false);
			}(window);
		}
    }
  }
</script>
<style>
	@import "../src/assets/css/base.css";
  body{
    margin: 0;
  }
  html,body,#app{
    height: 100%;
  }

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-image: url("../src/assets/image/bg.png");
  width: 100%;
  background-size: cover;
  overflow: hidden;

}
.banner-box .swiper-pagination{
	bottom: 1px !important;
}

.banner-box .swiper-pagination .swiper-pagination-bullet{
	width: 12%;
	height: 2px;
	background: #fff;
	opacity: 1;
	border-radius: 0;
}

.banner-box .swiper-pagination .swiper-pagination-bullet-active{
	background: #0079FF;
}

</style>
