<template>
    <div>
        <div class="footer-mobile" style="margin:0 auto 0;">
            <div class="footer-content">
                <div class="cont-img">
                    <img src="../../src/assets/image/logo-s.png" alt="">
                </div>
                <div class="text" @click="open">
                    咨询热线：18580875987 或 023-67665596
                </div>
                <div class="mailbox">
                    邮箱：service@mhkjcq.com
                </div>
                <div class="address">
                    地址：重庆市巴南区木洞镇天益路9号重庆国际生物城E3栋2单元
                </div>
            </div>
            <div class="copy-right-mobile">
                <div class="text1">Copyright © 2020-2022 重庆敏恒科技有限公司</div>
                <div class="text2">
                    <a href="https://beian.miit.gov.cn" target="_blank" style="color: #ffffff; text-decoration: none;">备案序号：渝ICP备2021011164号-1</a>
                    <a href="https://beian.mps.gov.cn/#/query/webSearch?code=50011302222017" rel="noreferrer" target="_blank" style="color: #ffffff; text-decoration: none;margin-left: 10px;display: flex;align-items: center;justify-content: center;margin-top: 4px">
                      <img style="width: 14px;margin-right: 4px" src="../assets/image/ab.png" alt="">
                      渝公网安备 50011302222017</a>
                </div>
            </div>
        </div>

        <!--点击咨询热线弹窗-->
        <div class="model-box22" v-show="modelShow">
            <div class="model-bg" @click="modelShow=false"></div>
            <div class="model-content">
                请选择咨询热线：<br/>
                商务交流：<a href="tel:18580875987">18580875987</a> 张先生<br/>
                <a style="margin-left: 32px" href="tel:023-67665596">023-67665596</a> <br/>
                <div class="btn1" @click="modelShow=false">确定</div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "mobileFooter",
        data() {
            return {
                modelShow:false
            }
        },
        methods: {
            open() {
                this.modelShow = true;
            },
        }
    }
</script>
<style>
    @import "../../src/assets/css/base.css";
</style>
