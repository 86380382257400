import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    // component: () => import(/* webpackChunkName: "about" */ '../views/home/index.vue'),
    meta:{
      keepAlive:false //不需要被缓存的组件
    }
  },
  {
    path: '/message',
    name: 'message',
    component: () => import(/* webpackChunkName: "about" */ '../views/message/index.vue'),
    meta:{
      keepAlive:false //不需要被缓存的组件
    }
  },
  {
    path: '/messageDetail',
    name: 'messageDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/message/Details.vue'),
    meta:{
      keepAlive:false //不需要被缓存的组件
    }
  },
  {
    path: '/server',
    name: 'server',
    component: () => import(/* webpackChunkName: "about" */ '../views/server/index.vue'),
    meta:{
      keepAlive:false //不需要被缓存的组件
    }
  },
  {
    path: '/technology',
    name: 'technology',
    component: () => import(/* webpackChunkName: "about" */ '../views/technology/index.vue'),
    meta:{
      keepAlive:false //不需要被缓存的组件
    }
  },
  {
    path: '/technologys',
    name: 'technologys',
    component: () => import(/* webpackChunkName: "about" */ '../views/technology/TechnologyViews.vue'),
    meta:{
      keepAlive:false //不需要被缓存的组件
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/about/index.vue'),
    meta:{
      keepAlive:false //不需要被缓存的组件
    }
  },
]

const router = new VueRouter({
  routes,
  mode: 'history',
})

export default router
