<template>
    <div>
        <template v-if="!wapShow">
            <div class="home">
                <div class="banner-box">
                    <div class="swiper-container">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide">
                                <router-link to="/technology?index=1">
                                    <img src="../../assets/image/banner4.png" alt="">
                                    <span class="swiper-slide-banner4-text1">熔融结晶</span>
                                    <span class="swiper-slide-banner4-text2">热敏及精馏难分离物系的优选分离工艺<br>高纯化学品生产的可靠解决方法</span>
                                </router-link>
                            </div>
                            <div class="swiper-slide">
                                <img src="../../assets/image/banner1.png" alt="">
                                <span class="swiper-slide-banner1-text">绿色化工践行者</span>
                            </div>
                            <div class="swiper-slide">
                                <img src="../../assets/image/banner2.png" alt="">
                                <span class="swiper-slide-banner1-text">模块化系统专家</span>
                            </div>
                            <div class="swiper-slide">
                                <img src="../../assets/image/banner3.png" alt="">
                                <span class="swiper-slide-banner1-text">智能化工建设者</span>
                            </div>
                        </div>
                        <div class="swiper-pagination"></div>
                    </div>
                </div>

                <div class="title">
                    技术&解决方案
                    <div class="title-en">TECHNOLOGY & SOLUTIO</div>
                </div>

                <div class="t-item t-item1">
                    <div class="t-item-title">单元分离技术及设备</div>
                    <div class="swiper-container">
                        <div class="swiper-wrapper"  style="font-size: 16px;">
                            <div class="swiper-slide">
                                <router-link to="/technology?index=1" class="item-detail">
                                    <img src="../../assets/image/item1.png" alt="">
                                    <div>熔融结晶</div>
                                </router-link>
                            </div>
                            <div class="swiper-slide">
                                <router-link to="/technology?index=2" class="item-detail">
                                    <img src="../../assets/image/item2.png" alt="">
                                    <div>升华结晶</div>
                                </router-link>
                            </div>
                            <div class="swiper-slide">
                                <router-link to="/technology?index=3" class="item-detail">
                                    <img src="../../assets/image/item3.png" alt="">
                                    <div>连续萃取</div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-button-prev">
                        <i class="prev iconfont icon-xiangzuojiantou"></i>
                    </div>
                    <div class="swiper-button-next">
                        <i class="next iconfont icon-xiangyoujiantou"></i>
                    </div>
                </div>
                <div class="t-item t-item2" style="margin-top: 34px">
                    <div class="t-item-title">产品工艺技术</div>
                    <div class="swiper-container"  style="font-size: 16px;">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide">
                                <router-link to="/technologys?index=1" class="item-detail">
                                    <img src="../../assets/image/item4.png" alt="">
                                    <div>锂电添加剂系列产品</div>
                                </router-link>
                            </div>
                            <div class="swiper-slide">
                                <router-link to="/technologys?index=2" class="item-detail">
                                    <img src="../../assets/image/item5.png" alt="">
                                    <div>氰化及衍生物系列产品</div>
                                </router-link>
                            </div>
                            <div class="swiper-slide">
                                <router-link to="/technologys?index=3" class="item-detail">
                                    <img src="../../assets/image/item6.png" alt="">
                                    <div>三废资源化利用技术</div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-button-prev">
                        <i class="prev iconfont icon-xiangzuojiantou"></i>
                    </div>
                    <div class="swiper-button-next">
                        <i class="next iconfont  icon-xiangyoujiantou"></i>
                    </div>
                </div>

                <div class="title">
                    服务流程
                    <div class="title-en">SERVICE</div>
                </div>
                <div class="service-box">
                    <div class="service-box-item">
                        <img src="../../assets/image/service1.png" alt="">
                        <router-link to="/server?index=1" class="service-line">
                            <div>研究与测试</div>
                            <div class="service-icon iconfont icon-youjiantou"></div>
                        </router-link>
                    </div>
                    <div class="service-box-item">
                        <img src="../../assets/image/service2.png" alt="">
                        <router-link to="/server?index=2" class="service-line">
                            <div>工程设计</div>
                            <div class="service-icon iconfont icon-youjiantou"></div>
                        </router-link>
                    </div>
                    <div class="service-box-item">
                        <img src="../../assets/image/service3.png" alt="">
                        <router-link to="/server?index=3" class="service-line">
                            <div>设备制造和系统集成</div>
                            <div class="service-icon iconfont icon-youjiantou"></div>
                        </router-link>
                    </div>
                    <div class="service-box-item">
                        <img src="../../assets/image/service4.png" alt="">
                        <router-link to="/server?index=4" class="service-line">
                            <div>交付及售后服务</div>
                            <div class="service-icon iconfont icon-youjiantou"></div>
                        </router-link>
                    </div>
                    <div style="clear: both"></div>
                </div>
                <div class="title">
                    资讯与动态
                    <div class="title-en">NEWS</div>
                </div>
                <div class="news-box">
                    <div class="news-box-item" v-for="item in lists2" @click="to_detail(item.id)">
                        <img :src="require('@/assets/image/message/'+item.imgurl)"/>
                        <div class="news-box-title">{{item.name}}</div>
                        <div class="news-box-cont">{{item.contents}}</div>
                        <div class="news-box-time">{{item.time}}</div>
                    </div>
                </div>
                <div class="news-box">
                    <div class="news-box-item" v-for="item in lists" @click="to_detail(item.id)">
                        <img :src="require('@/assets/image/message/'+item.imgurl)"/>
                        <div class="news-box-title">{{item.name}}</div>
                        <div class="news-box-cont">{{item.contents}}</div>
                        <div class="news-box-time">{{item.time}}</div>
                    </div>
                </div>
            </div>
        </template>

        <template v-else>
            <home-wap></home-wap>
        </template>
    </div>
</template>

<script>
    import Swiper, {Navigation, Pagination, Autoplay} from 'swiper';
    import eventBus from "@/assets/js/eventBus";
    import homeWap from "./wap/index"

    export default {
        name: 'HomeView',
        components: {homeWap},
        data() {
            return {
                lists: [],
                lists2:[],
                wapShow:false
            }
        },
        mounted() {
            document.getElementsByTagName("title")[0].innerText = '「重庆敏恒科技」专注熔融结晶|VC熔融结晶|FEC熔融结晶';
            this.lists = JSON.parse(JSON.stringify(require('@/assets/js/data.js').default)).splice(1, 3);
            this.lists2 = JSON.parse(JSON.stringify(require('@/assets/js/data.js').default)).splice(5, 7);
            let swiper1 = new Swiper('.banner-box .swiper-container', {
                autoplay: {
                },
                loop: true,
                pagination: {
                    el: '.banner-box .swiper-pagination',
                    clickable: true,
                },

            });
            let swiper2 = new Swiper('.t-item1 .swiper-container', {
                autoplay: {
                    pauseOnMouseEnter: true,
                },
                slidesPerView: 3,
                effect: "coverflow",
                coverflowEffect: {
                    rotate: 0,
                    stretch: 0, //每个slide之间的拉伸值（距离），越大slide靠得越紧。 默认0。
                    depth: 0, //slide的位置深度。值越大z轴距离越远，看起来越小。 默认100。
                    modifier: 1,//depth和rotate和stretch的倍率，相当于            depth*modifier、rotate*modifier、stretch*modifier，值越大这三个参数的效果越明显。默认1。
                    slideShadows: false, //开启slide阴影。默认 true。
                },
                loop: true,
                navigation: {
                    nextEl: '.t-item1 .swiper-button-next',
                    prevEl: '.t-item1 .swiper-button-prev',
                },

            });
            let swiper3 = new Swiper('.t-item2 .swiper-container', {
                autoplay: {
                    pauseOnMouseEnter: true,
                },
                effect: "coverflow",
                coverflowEffect: {
                    rotate: 0,
                    stretch: 0, //每个slide之间的拉伸值（距离），越大slide靠得越紧。 默认0。
                    depth: 0, //slide的位置深度。值越大z轴距离越远，看起来越小。 默认100。
                    modifier: 1,//depth和rotate和stretch的倍率，相当于            depth*modifier、rotate*modifier、stretch*modifier，值越大这三个参数的效果越明显。默认1。
                    slideShadows: false, //开启slide阴影。默认 true。
                },
                slidesPerView: 3,
                loop: true,
                navigation: {
                    nextEl: '.t-item2 .swiper-button-next',
                    prevEl: '.t-item2 .swiper-button-prev',
                },

            });

            //切换手机端
            if(this.$route.query) this.wapShow = this.$route.query.wapShow;
            eventBus.$on("wapShow",(data)=>{
                this.wapShow= data;
            });
        },
        methods: {
            to_detail(id) {
                this.$router.push({
                    name: "messageDetail",
                    query: {
                        id: id
                    }
                })
            }
        }

    }
</script>
<style scoped>

    /*@import "../../assets/css/home.css";*/

    .title {
        margin-top: 62px;
        margin-bottom: 38px;
        text-align: center;
        font-size: 30px;
        font-weight: 500;
        color: #363A44;
        letter-spacing: 1px;
        position: relative;
    }

    .title-en {
        position: absolute;
        text-align: center;
        font-size: 40px;
        font-family: Helvetica;
        color: rgba(54, 58, 68, 0.08);
        top: -22px;
        width: 100%;
    }
    .banner-box{
        height: 400px;
        overflow: hidden;
    }
    .banner-box .swiper-container{
        height: 400px;
        border-radius: 0px 0px 20px 20px;
    }
    .banner-box .swiper-container .swiper-wrapper{
        display: flex;
    }
    .banner-box img{
        height: 400px;
        object-fit: cover;
    }

    .banner-box .swiper-pagination{
        bottom: 20px !important;
    }

    .banner-box .swiper-pagination .swiper-pagination-bullet{
        width: 127px;
        height: 2px;
        background: #fff;
        opacity: 1;
        border-radius: 0;
    }

    .banner-box .swiper-pagination .swiper-pagination-bullet-active{
        background: #0079FF;
    }
    .t-item .swiper-container{
        top: 116px;
        width: 814px;
    }
    .t-item .swiper-container .swiper-slide{
        display: flex;
        justify-content: center;
    }
    .prev,.next{
        color: #3A3A3A !important;
        font-size: 35px !important;
        font-weight: bold;
    }
    .prev:hover,.next:hover{
        color: #0079FF !important;
    }
    .prev::after,.next::after{
        display: none;
    }
    .item-detail{
        margin: 0 16px;
        text-decoration: none;
    }
    .item-detail img{
        width: 250px;
        height: 190px;
        object-fit: cover;
        border-radius: 20px 20px 0px 0px;
    }
    .item-detail div{
        width: 230px;
        height: 60px;
        line-height: 60px;
        padding-left: 20px;
        background: rgba(52, 52, 52, 0.9);
        text-align: start;
        border-radius: 0px 0px 20px 20px;
        margin-top: -4px;
        color: #fff;
    }
    .t-item{
        height: 442px;
        background-image: url("../../assets/image/item-bg.png");
        background-size: contain;
        position: relative;
    }
    .t-item-title{
        position: absolute;
        top: 38px;
        text-align: center;
        font-size: 26px;
        font-weight: 500;
        color: #3A3A3A;
        width: 100%;
    }
    .service-box-item a{
        text-decoration: none;
    }
    .service-box-item{
        float: left;
        width: 536px;
        cursor: pointer;
        margin-bottom: 21px;
    }
    .service-box-item:hover .service-icon{
        color: #0079FF;
    }
    .service-box-item:hover .service-line{
        border-color: #0079FF;
    }
    .service-icon{
        color: #363A44;
        font-size: 21px !important;
    }
    .service-box-item img{
        width: 536px;
        height: 254px;
        object-fit: cover;
    }
    .service-box-item:nth-child(2n){
        margin-left: 28px;
    }

    .service-line{
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        color: #363A44;
        border-bottom: 1px solid rgba(54, 58, 68, 0.14);
    }
    .news-box{
        display: flex;
        justify-content: space-between;
        margin-bottom: 48px;
    }
    .news-box-item{
        width: 350px;
        height: 394px;
        background: #FFFFFF;
        box-shadow: 0px 0px 20px 4px rgba(0, 0, 0, 0.04);
        border-radius: 20px 20px 0 0;
        overflow: hidden;
        text-align: start;
        cursor: pointer;
    }
    .news-box-item img{
        width: 350px;
        height: 224px;
        object-fit: cover;
    }
    .news-box-title{
        margin: 12px 20px;
        font-size: 18px;
        color: #000;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .news-box-title:hover{
        color: #0079FF;
    }
    .news-box-cont{
        margin: 0 20px;
        font-size: 14px;
        color: #828282;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .news-box-time{
        margin: 12px 20px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.49);;
    }

    /*banner图文字*/
    .swiper-slide-banner4-text1{
        position: absolute;
        top:90px;
        left:148px;
        width: 240px;
        height: 84px;
        font-size: 60px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 84px;
    }
    .swiper-slide-banner4-text2{
        position: absolute;
        left:148px;
        top:180px;
        text-align: left;
        width: 680px;
        height: 112px;
        font-size: 40px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 56px;
    }
    .swiper-slide-banner1-text{
        position: absolute;
        top:167px;
        left:47px;
        width: 1006px;
        height: 70px;
        font-size: 50px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 70px;
    }

    .swiper-button-prev:after,.swiper-button-next:after{
        display: none;
    }


</style>
